import { type Component, ErrorBoundary, Show } from 'solid-js';

import * as Sentry from '@sentry/browser';
import Routes from './Router';
import UnderConstruction from './components/UnderConstruction';
import { StateProvider } from './signals/context';

const App: Component = () => {
  return (
    <ErrorBoundary
      fallback={error => {
        console.error(error);
        Sentry.captureException(error);
        // error may be related to token and could be solved
        // by signing in again
        localStorage.clear();
        return <div>An unexpected error ocurred. Please refresh the page</div>;
      }}
    >
      <StateProvider>
        <Show
          when={import.meta.env.VITE_UNDER_CONSTRUCTION !== 'true'}
          fallback={<UnderConstruction/>}
        >

          <Routes />
        </Show>
      </StateProvider>
    </ErrorBoundary>
  );
};

export default App;
